import { useStaticQuery, graphql } from 'gatsby';

const usePages = () => {

    const pages = useStaticQuery( graphql `
        {
            allSitePage {
                nodes {
                  path
                }
              }
        }
    `);

    return pages
}
 
export default usePages;