import { useStaticQuery, graphql } from 'gatsby';

const useCategories = () => {

    const categories = useStaticQuery( graphql `
        {
            allStrapiEntityCategoryPages { 
                nodes { 
                  id
                  slug
                  category_h3_title
                  en_slug
                  en_category_h3_title
                } 
            }
        }
    `);

    return categories.allStrapiEntityCategoryPages.nodes.map( category => ({
        id: category.id,
        slug: category.slug,
        en_slug: category.en_slug,
        h3_title: category.category_h3_title,
        en_h3_title: category.en_category_h3_title
    }))
}
 
export default useCategories;
