import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import urlSlug from 'url-slug';



const FooterBackground = styled.footer`
    background-color: #881111;
    padding: 1rem;
`;

const DivLink = styled.div`
    &:hover {
        background-color: #313282;
    }
`;

const CategorySection = styled.ul`
    max-width: 1200px;
    width: 95%;
    margin: 4rem auto 2rem auto;
    text-align: center;
    @media (min-width: 480px) {
        display: grid;
        grid-template-columns: repeat(1, 0.5fr);
        row-gap: 2rem;
        column-gap: 2rem;
    }
    @media (min-width: 768px) {
        grid-template-columns: repeat(5, 1fr);
    }
`;

const PictureBackgroundDiv = styled.div`
    color:  #EEAAAA;
    height: 100%;
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    h3{
        font-size: 2rem;
        margin: 0;
        max-width: 800px;
    }

    p {
        color: #BBB;  
        font-size: 1.2rem;
        text-align: center;  
    }

    @media (min-width: 992px) {
        h3 {
            font-size: 4rem;
        }    

        p {
            font-size: 2rem;
        } 
    }
`;



const Footer = ({h3_section, p_section, categories, lan }) => {




    return ( 
        <FooterBackground>
            <PictureBackgroundDiv>
                <h3>{h3_section}</h3>
                <p>{p_section}</p>
            </PictureBackgroundDiv>
            <CategorySection>
                { categories.map( category => (
                    category.slug !== 'config' ?
                    <DivLink key={category.id}>
                        <Link to={ '/' + urlSlug( lan === 'es' ? category.slug : category.en_slug ) } activeClassName="current-page" >
                            { lan === 'es' ? category.h3_title : category.en_h3_title }
                        </Link>
                    </DivLink>
                    :
                    ''
                ))}    
            </CategorySection>
            <PictureBackgroundDiv>
            <Link to={lan === 'es' ? '/mapa-del-sitio' : '/site-map'} activeClassName="current-page" >
                            { lan === 'es' ? 'Mapa del sitio' : 'Site map' }
                        </Link>
            </PictureBackgroundDiv>
        </FooterBackground>
    );
}
 
export default Footer;

