import { useStaticQuery, graphql } from 'gatsby';

const useHomeMetadata = () => {

  const homeMetadata = useStaticQuery( graphql `
        {
          allStrapiEntityHomeSeo { 
            nodes {
              id
              seo_title
              seo_description
              seo_keywords
              banner_h1
              banner_h2
              category_h3_section
              category_p_section
              en_seo_title
              en_seo_description
              en_seo_keywords
              en_banner_h1
              en_banner_h2
              en_category_h3_section
              en_category_p_section
              start_text
              end_text
              start_text_en
              end_text_en
            } 
          }

        }
    `);

    return homeMetadata.allStrapiEntityHomeSeo.nodes.map( meta => ({
        id: meta.id,
        seo_title: meta.seo_title,
        seo_description: meta.seo_description,
        seo_keywords: meta.seo_keywords,
        banner_h1: meta.banner_h1,
        banner_h2: meta.banner_h2,
        category_h3_section: meta.category_h3_section,
        category_p_section: meta.category_p_section,
        en_seo_title: meta.en_seo_title,
        en_seo_description: meta.en_seo_description,
        en_seo_keywords: meta.en_seo_keywords,
        en_banner_h1: meta.en_banner_h1,
        en_banner_h2: meta.en_banner_h2,
        en_category_h3_section: meta.en_category_h3_section,
        en_category_p_section: meta.en_category_p_section,
        start_text:meta.start_text,
        end_text:meta.end_text,
        start_text_en:meta.start_text_en,
        end_text_en:meta.end_text_en
    }))
}
 
export default useHomeMetadata;
